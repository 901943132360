@font-face {
  font-family: 'Artwork_icons';
  src: url('fonts/Artwork_icons.eot?ybefym');
  src: url('fonts/Artwork_icons.eot?ybefym#iefix') format('embedded-opentype'), url('fonts/Artwork_icons.ttf?ybefym') format('truetype'), url('fonts/Artwork_icons.woff?ybefym') format('woff'), url('fonts/Artwork_icons.svg?ybefym#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Artwork_icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chart-bar-outline:before {
  content: "\ea94";
}
.icon-chart-pie-outline:before {
  content: "\ea95";
}
.icon-chart-pie1:before {
  content: "\ea96";
}
.icon-chart-bar1:before {
  content: "\ea97";
}
.icon-error_outline:before {
  content: "\e905";
}
.icon-not_interested:before {
  content: "\e906";
}
.icon-skip_next:before {
  content: "\e907";
}
.icon-skip_previous:before {
  content: "\e908";
}
.icon-stop:before {
  content: "\e909";
}
.icon-fiber_manual_record:before {
  content: "\e90a";
}
.icon-slow_motion_video:before {
  content: "\e90b";
}
.icon-call_made:before {
  content: "\e90c";
}
.icon-call_merge:before {
  content: "\e90d";
}
.icon-call_missed:before {
  content: "\e90e";
}
.icon-call_received:before {
  content: "\e90f";
}
.icon-call_split:before {
  content: "\e910";
}
.icon-chat:before {
  content: "\e911";
}
.icon-forum:before {
  content: "\e912";
}
.icon-import_export:before {
  content: "\e913";
}
.icon-chat_bubble:before {
  content: "\e914";
}
.icon-chat_bubble_outline:before {
  content: "\e915";
}
.icon-mail_outline:before {
  content: "\e916";
}
.icon-add:before {
  content: "\e917";
}
.icon-add_circle:before {
  content: "\e918";
}
.icon-add_circle_outline:before {
  content: "\e919";
}
.icon-block:before {
  content: "\e91a";
}
.icon-filter_list:before {
  content: "\e91b";
}
.icon-remove:before {
  content: "\e91c";
}
.icon-remove_circle:before {
  content: "\e91d";
}
.icon-remove_circle_outline:before {
  content: "\e91e";
}
.icon-send:before {
  content: "\e91f";
}
.icon-text_format:before {
  content: "\e920";
}
.icon-outlined_flag:before {
  content: "\e921";
}
.icon-save_alt:before {
  content: "\e922";
}
.icon-how_to_reg:before {
  content: "\e923";
}
.icon-access_alarm:before {
  content: "\e924";
}
.icon-access_alarms:before {
  content: "\e925";
}
.icon-access_time:before {
  content: "\e926";
}
.icon-add_alarm:before {
  content: "\e927";
}
.icon-border_color:before {
  content: "\e928";
}
.icon-format_bold:before {
  content: "\e929";
}
.icon-format_color_fill:before {
  content: "\e92a";
}
.icon-format_color_text:before {
  content: "\e92b";
}
.icon-format_shapes:before {
  content: "\e92c";
}
.icon-title:before {
  content: "\e92d";
}
.icon-format_list_numbered_rtl:before {
  content: "\e92e";
}
.icon-insert_chart_outlined:before {
  content: "\e92f";
}
.icon-bar_chart:before {
  content: "\e930";
}
.icon-notes:before {
  content: "\e931";
}
.icon-attachment1:before {
  content: "\e932";
}
.icon-cloud_off:before {
  content: "\e933";
}
.icon-folder_open:before {
  content: "\e934";
}
.icon-keyboard_arrow_down:before {
  content: "\e935";
}
.icon-keyboard_arrow_left:before {
  content: "\e936";
}
.icon-keyboard_arrow_right:before {
  content: "\e937";
}
.icon-keyboard_arrow_up:before {
  content: "\e938";
}
.icon-keyboard_backspace:before {
  content: "\e939";
}
.icon-keyboard_capslock:before {
  content: "\e93a";
}
.icon-keyboard_return:before {
  content: "\e93b";
}
.icon-keyboard_tab:before {
  content: "\e93c";
}
.icon-adjust:before {
  content: "\e93d";
}
.icon-audiotrack:before {
  content: "\e93e";
}
.icon-center_focus_weak:before {
  content: "\e93f";
}
.icon-crop:before {
  content: "\e940";
}
.icon-crop_free:before {
  content: "\e941";
}
.icon-crop_original:before {
  content: "\e942";
}
.icon-dehaze:before {
  content: "\e943";
}
.icon-filter_center_focus:before {
  content: "\e944";
}
.icon-filter_tilt_shift:before {
  content: "\e945";
}
.icon-navigate_before:before {
  content: "\e946";
}
.icon-navigate_next:before {
  content: "\e947";
}
.icon-rotate_left:before {
  content: "\e948";
}
.icon-rotate_right:before {
  content: "\e949";
}
.icon-straighten:before {
  content: "\e94a";
}
.icon-timelapse:before {
  content: "\e94b";
}
.icon-timer:before {
  content: "\e94c";
}
.icon-timer_off:before {
  content: "\e94d";
}
.icon-transform:before {
  content: "\e94e";
}
.icon-tune:before {
  content: "\e94f";
}
.icon-arrow_back:before {
  content: "\e950";
}
.icon-arrow_drop_down:before {
  content: "\e951";
}
.icon-arrow_drop_up:before {
  content: "\e952";
}
.icon-arrow_forward:before {
  content: "\e953";
}
.icon-check:before {
  content: "\e954";
}
.icon-expand_less:before {
  content: "\e955";
}
.icon-expand_more:before {
  content: "\e956";
}
.icon-fullscreen:before {
  content: "\e957";
}
.icon-fullscreen_exit:before {
  content: "\e958";
}
.icon-menu:before {
  content: "\e959";
}
.icon-keyboard_control:before {
  content: "\e95a";
}
.icon-more_vert:before {
  content: "\e95b";
}
.icon-refresh:before {
  content: "\e95c";
}
.icon-unfold_less:before {
  content: "\e95d";
}
.icon-unfold_more:before {
  content: "\e95e";
}
.icon-arrow_upward:before {
  content: "\e95f";
}
.icon-subdirectory_arrow_left:before {
  content: "\e960";
}
.icon-subdirectory_arrow_right:before {
  content: "\e961";
}
.icon-arrow_downward:before {
  content: "\e962";
}
.icon-first_page:before {
  content: "\e963";
}
.icon-last_page:before {
  content: "\e964";
}
.icon-arrow_left:before {
  content: "\e965";
}
.icon-arrow_right:before {
  content: "\e966";
}
.icon-do_not_disturb_alt:before {
  content: "\e967";
}
.icon-pie_chart_outlined:before {
  content: "\e968";
}
.icon-people_outline:before {
  content: "\e969";
}
.icon-person_outline:before {
  content: "\e96a";
}
.icon-share:before {
  content: "\e96b";
}
.icon-alarm_on:before {
  content: "\e96c";
}
.icon-bookmark_outline:before {
  content: "\e96d";
}
.icon-change_history:before {
  content: "\e96e";
}
.icon-code:before {
  content: "\e96f";
}
.icon-done:before {
  content: "\e970";
}
.icon-done_all:before {
  content: "\e971";
}
.icon-highlight_remove:before {
  content: "\e972";
}
.icon-history1:before {
  content: "\e973";
}
.icon-info_outline:before {
  content: "\e974";
}
.icon-label_outline:before {
  content: "\e975";
}
.icon-language:before {
  content: "\e976";
}
.icon-launch:before {
  content: "\e977";
}
.icon-list:before {
  content: "\e978";
}
.icon-lock_open:before {
  content: "\e979";
}
.icon-lock_outline:before {
  content: "\e97a";
}
.icon-search:before {
  content: "\e97b";
}
.icon-settings_backup_restore:before {
  content: "\e97c";
}
.icon-subject:before {
  content: "\e97d";
}
.icon-swap_horiz:before {
  content: "\e97e";
}
.icon-swap_vert:before {
  content: "\e97f";
}
.icon-trending_neutral:before {
  content: "\e980";
}
.icon-donut_large:before {
  content: "\e981";
}
.icon-check_circle_outline:before {
  content: "\e982";
}
.icon-delete_outline:before {
  content: "\e983";
}
.icon-label_important:before {
  content: "\e984";
}
.icon-label_important_outline:before {
  content: "\e985";
}
.icon-drive_file_rename_outline:before {
  content: "\e986";
}
.icon-file_download_done:before {
  content: "\e987";
}
.icon-logout:before {
  content: "\e988";
}
.icon-menu_open:before {
  content: "\e989";
}
.icon-all_inclusive:before {
  content: "\e98a";
}
.icon-add_road:before {
  content: "\e98b";
}
.icon-alt_route:before {
  content: "\e98c";
}
.icon-close_fullscreen:before {
  content: "\e98d";
}
.icon-design_services:before {
  content: "\e98e";
}
.icon-do_not_step:before {
  content: "\e98f";
}
.icon-history_toggle_off:before {
  content: "\e990";
}
.icon-open_in_full:before {
  content: "\e991";
}
.icon-person_add_alt:before {
  content: "\e992";
}
.icon-person_remove:before {
  content: "\e993";
}
.icon-rule:before {
  content: "\e994";
}
.icon-south:before {
  content: "\e995";
}
.icon-south_east:before {
  content: "\e996";
}
.icon-south_west:before {
  content: "\e997";
}
.icon-upgrade:before {
  content: "\e998";
}
.icon-west:before {
  content: "\e999";
}
.icon-alert-triangle:before {
  content: "\e99a";
}
.icon-bar-chart1:before {
  content: "\ea8a";
}
.icon-briefcase:before {
  content: "\e99b";
}
.icon-check1:before {
  content: "\e99c";
}
.icon-check-circle:before {
  content: "\e99d";
}
.icon-clock1:before {
  content: "\e99e";
}
.icon-crop1:before {
  content: "\e99f";
}
.icon-globe:before {
  content: "\e9a0";
}
.icon-layers:before {
  content: "\e9a1";
}
.icon-list1:before {
  content: "\e9a2";
}
.icon-maximize:before {
  content: "\e9a3";
}
.icon-menu1:before {
  content: "\e9a4";
}
.icon-minimize-2:before {
  content: "\e9a5";
}
.icon-pen-tool:before {
  content: "\e9a6";
}
.icon-pie-chart1:before {
  content: "\ea8b";
}
.icon-plus:before {
  content: "\e9a7";
}
.icon-power:before {
  content: "\e9a8";
}
.icon-shield:before {
  content: "\e9a9";
}
.icon-slash:before {
  content: "\e9aa";
}
.icon-trash-2:before {
  content: "\e9ab";
}
.icon-search1:before {
  content: "\e9ac";
}
.icon-zoomin:before {
  content: "\e9ad";
}
.icon-zoomout:before {
  content: "\e9ae";
}
.icon-meter1:before {
  content: "\e9af";
}
.icon-switch:before {
  content: "\e9b0";
}
.icon-forbidden:before {
  content: "\e9b1";
}
.icon-lock:before {
  content: "\e9b2";
}
.icon-unlocked:before {
  content: "\e9b3";
}
.icon-user:before {
  content: "\e9b4";
}
.icon-graph:before {
  content: "\ea8c";
}
.icon-bars:before {
  content: "\ea8d";
}
.icon-chart:before {
  content: "\e9b5";
}
.icon-chart3:before {
  content: "\ea93";
}
.icon-file-empty:before {
  content: "\ea5d";
}
.icon-files-empty:before {
  content: "\ea5e";
}
.icon-file-text2:before {
  content: "\ea5f";
}
.icon-file-picture:before {
  content: "\ea60";
}
.icon-copy:before {
  content: "\ea61";
}
.icon-stack:before {
  content: "\ea62";
}
.icon-compass:before {
  content: "\ea63";
}
.icon-map2:before {
  content: "\ea64";
}
.icon-history:before {
  content: "\ea65";
}
.icon-clock:before {
  content: "\ea66";
}
.icon-alarm:before {
  content: "\ea67";
}
.icon-stopwatch:before {
  content: "\ea68";
}
.icon-display:before {
  content: "\ea69";
}
.icon-undo:before {
  content: "\ea6a";
}
.icon-redo:before {
  content: "\ea6b";
}
.icon-undo2:before {
  content: "\ea6c";
}
.icon-redo2:before {
  content: "\ea6d";
}
.icon-user-plus:before {
  content: "\ea6e";
}
.icon-user-minus:before {
  content: "\ea6f";
}
.icon-user-check:before {
  content: "\ea70";
}
.icon-spinner11:before {
  content: "\ea71";
}
.icon-enlarge2:before {
  content: "\ea72";
}
.icon-shrink2:before {
  content: "\ea73";
}
.icon-equalizer:before {
  content: "\ea74";
}
.icon-equalizer2:before {
  content: "\ea75";
}
.icon-cogs:before {
  content: "\ea76";
}
.icon-stats-dots:before {
  content: "\ea77";
}
.icon-stats-bars:before {
  content: "\ea78";
}
.icon-meter:before {
  content: "\ea79";
}
.icon-meter2:before {
  content: "\ea7a";
}
.icon-bookmark:before {
  content: "\ea7b";
}
.icon-bookmarks:before {
  content: "\ea7c";
}
.icon-warning:before {
  content: "\ea7d";
}
.icon-ctrl:before {
  content: "\ea7e";
}
.icon-radio-checked:before {
  content: "\ea7f";
}
.icon-radio-unchecked:before {
  content: "\ea80";
}
.icon-ungroup:before {
  content: "\ea81";
}
.icon-text-height:before {
  content: "\ea82";
}
.icon-text-width:before {
  content: "\ea83";
}
.icon-font-size:before {
  content: "\ea84";
}
.icon-bold:before {
  content: "\ea85";
}
.icon-underline:before {
  content: "\ea86";
}
.icon-italic:before {
  content: "\ea87";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-codepen:before {
  content: "\eae8";
}
.icon-stats:before {
  content: "\ea92";
}
.icon-file-empty:before {
  content: "\ea5d";
}
.icon-files-empty:before {
  content: "\ea5e";
}
.icon-file-text2:before {
  content: "\ea5f";
}
.icon-file-picture:before {
  content: "\ea60";
}
.icon-copy:before {
  content: "\ea61";
}
.icon-stack:before {
  content: "\ea62";
}
.icon-compass:before {
  content: "\ea63";
}
.icon-map2:before {
  content: "\ea64";
}
.icon-history:before {
  content: "\ea65";
}
.icon-clock:before {
  content: "\ea66";
}
.icon-alarm:before {
  content: "\ea67";
}
.icon-stopwatch:before {
  content: "\ea68";
}
.icon-display:before {
  content: "\ea69";
}
.icon-undo:before {
  content: "\ea6a";
}
.icon-redo:before {
  content: "\ea6b";
}
.icon-undo2:before {
  content: "\ea6c";
}
.icon-redo2:before {
  content: "\ea6d";
}
.icon-user-plus:before {
  content: "\ea6e";
}
.icon-user-minus:before {
  content: "\ea6f";
}
.icon-user-check:before {
  content: "\ea70";
}
.icon-spinner11:before {
  content: "\ea71";
}
.icon-enlarge2:before {
  content: "\ea72";
}
.icon-shrink2:before {
  content: "\ea73";
}
.icon-equalizer:before {
  content: "\ea74";
}
.icon-equalizer2:before {
  content: "\ea75";
}
.icon-cogs:before {
  content: "\ea76";
}
.icon-stats-dots:before {
  content: "\ea77";
}
.icon-stats-bars:before {
  content: "\ea78";
}
.icon-meter:before {
  content: "\ea79";
}
.icon-meter2:before {
  content: "\ea7a";
}
.icon-bookmark:before {
  content: "\ea7b";
}
.icon-bookmarks:before {
  content: "\ea7c";
}
.icon-warning:before {
  content: "\ea7d";
}
.icon-ctrl:before {
  content: "\ea7e";
}
.icon-radio-checked:before {
  content: "\ea7f";
}
.icon-radio-unchecked:before {
  content: "\ea80";
}
.icon-ungroup:before {
  content: "\ea81";
}
.icon-text-height:before {
  content: "\ea82";
}
.icon-text-width:before {
  content: "\ea83";
}
.icon-font-size:before {
  content: "\ea84";
}
.icon-bold:before {
  content: "\ea85";
}
.icon-underline:before {
  content: "\ea86";
}
.icon-italic:before {
  content: "\ea87";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-codepen:before {
  content: "\eae8";
}
.icon-document-graph:before {
  content: "\ea90";
}
.icon-document-diagrams:before {
  content: "\ea91";
}
.icon-chronometer:before {
  content: "\e900";
}
.icon-sitemap:before {
  content: "\e901";
}
.icon-chart1:before {
  content: "\ea88";
}
.icon-chart2:before {
  content: "\ea89";
}
.icon-attachment:before {
  content: "\e902";
}
.icon-pin:before {
  content: "\e903";
}
.icon-square:before {
  content: "\e904";
}
.icon-call_missed:before {
  content: "\e90e";
}
.icon-call_received:before {
  content: "\e90f";
}
.icon-call_split:before {
  content: "\e910";
}
.icon-chat:before {
  content: "\e911";
}
.icon-forum:before {
  content: "\e912";
}
.icon-import_export:before {
  content: "\e913";
}
.icon-chat_bubble:before {
  content: "\e914";
}
.icon-chat_bubble_outline:before {
  content: "\e915";
}
.icon-mail_outline:before {
  content: "\e916";
}
.icon-add:before {
  content: "\e917";
}
.icon-add_circle:before {
  content: "\e918";
}
.icon-add_circle_outline:before {
  content: "\e919";
}
.icon-block:before {
  content: "\e91a";
}
.icon-filter_list:before {
  content: "\e91b";
}
.icon-remove:before {
  content: "\e91c";
}
.icon-remove_circle:before {
  content: "\e91d";
}
.icon-remove_circle_outline:before {
  content: "\e91e";
}
.icon-send:before {
  content: "\e91f";
}
.icon-text_format:before {
  content: "\e920";
}
.icon-outlined_flag:before {
  content: "\e921";
}
.icon-save_alt:before {
  content: "\e922";
}
.icon-how_to_reg:before {
  content: "\e923";
}
.icon-access_alarm:before {
  content: "\e924";
}
.icon-access_alarms:before {
  content: "\e925";
}
.icon-access_time:before {
  content: "\e926";
}
.icon-add_alarm:before {
  content: "\e927";
}
.icon-border_color:before {
  content: "\e928";
}
.icon-format_bold:before {
  content: "\e929";
}
.icon-format_color_fill:before {
  content: "\e92a";
}
.icon-format_color_text:before {
  content: "\e92b";
}
.icon-format_shapes:before {
  content: "\e92c";
}
.icon-title:before {
  content: "\e92d";
}
.icon-format_list_numbered_rtl:before {
  content: "\e92e";
}
.icon-insert_chart_outlined:before {
  content: "\e92f";
}
.icon-bar_chart:before {
  content: "\e930";
}
.icon-notes:before {
  content: "\e931";
}
.icon-attachment1:before {
  content: "\e932";
}
.icon-cloud_off:before {
  content: "\e933";
}
.icon-folder_open:before {
  content: "\e934";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-pie-chart:before {
  content: "\f200";
}
.icon-line-chart:before {
  content: "\f201";
}
.icon-chevron-small-left:before {
  content: "\e9ba";
}
.icon-chevron-small-right:before {
  content: "\e9bb";
}
.icon-chevron-small-up:before {
  content: "\e9bc";
}
.icon-chevron-thin-down:before {
  content: "\e9bd";
}
.icon-chevron-thin-left:before {
  content: "\e9be";
}
.icon-chevron-down:before {
  content: "\e9b6";
}
.icon-chevron-left:before {
  content: "\e9b7";
}
.icon-chevron-right:before {
  content: "\e9b8";
}
.icon-chevron-small-down:before {
  content: "\e9b9";
}
.icon-chevron-small-left:before {
  content: "\e9ba";
}
.icon-chevron-small-right:before {
  content: "\e9bb";
}
.icon-chevron-small-up:before {
  content: "\e9bc";
}
.icon-chevron-thin-down:before {
  content: "\e9bd";
}
.icon-chevron-thin-left:before {
  content: "\e9be";
}
.icon-chevron-thin-right:before {
  content: "\e9bf";
}
.icon-chevron-thin-up:before {
  content: "\e9c0";
}
.icon-chevron-up:before {
  content: "\e9c1";
}
.icon-chevron-with-circle-down:before {
  content: "\e9c2";
}
.icon-chevron-with-circle-left:before {
  content: "\e9c3";
}
.icon-chevron-with-circle-right:before {
  content: "\e9c4";
}
.icon-chevron-with-circle-up:before {
  content: "\e9c5";
}
.icon-dots-three-horizontal:before {
  content: "\e9c6";
}
.icon-dots-three-vertical:before {
  content: "\e9c7";
}
.icon-flow-tree:before {
  content: "\e9c8";
}
.icon-resize-100:before {
  content: "\e9c9";
}
.icon-resize-full-screen:before {
  content: "\e9ca";
}
.icon-attachment2:before {
  content: "\e9cb";
}
.icon-back-in-time:before {
  content: "\e9cc";
}
.icon-bar-graph:before {
  content: "\ea8e";
}
.icon-block1:before {
  content: "\e9cd";
}
.icon-brush:before {
  content: "\e9ce";
}
.icon-calendar:before {
  content: "\e9cf";
}
.icon-ccw:before {
  content: "\e9d0";
}
.icon-circle-with-cross:before {
  content: "\e9d1";
}
.icon-circle-with-minus:before {
  content: "\e9d2";
}
.icon-circle-with-plus:before {
  content: "\e9d3";
}
.icon-circle:before {
  content: "\e9d4";
}
.icon-circular-graph:before {
  content: "\e9d5";
}
.icon-cw:before {
  content: "\e9d6";
}
.icon-cycle:before {
  content: "\e9d7";
}
.icon-export:before {
  content: "\e9d8";
}
.icon-grid:before {
  content: "\e9d9";
}
.icon-info-with-circle:before {
  content: "\e9da";
}
.icon-link:before {
  content: "\e9db";
}
.icon-list2:before {
  content: "\e9dc";
}
.icon-menu2:before {
  content: "\e9dd";
}
.icon-minus:before {
  content: "\e9de";
}
.icon-new-message:before {
  content: "\e9df";
}
.icon-pie-chart2:before {
  content: "\ea8f";
}
.icon-pin1:before {
  content: "\e9e0";
}
.icon-plus1:before {
  content: "\e9e1";
}
.icon-ruler:before {
  content: "\e9e2";
}
.icon-scissors:before {
  content: "\e9e3";
}
.icon-share-alternitive:before {
  content: "\e9e4";
}
.icon-share1:before {
  content: "\e9e5";
}
.icon-sound-mix:before {
  content: "\e9e6";
}
.icon-squared-cross:before {
  content: "\e9e7";
}
.icon-squared-minus:before {
  content: "\e9e8";
}
.icon-squared-plus:before {
  content: "\e9e9";
}
.icon-stopwatch1:before {
  content: "\e9ea";
}
.icon-swap:before {
  content: "\e9eb";
}
.icon-time-slot:before {
  content: "\e9ec";
}
.icon-add-outline:before {
  content: "\e9ed";
}
.icon-add-solid:before {
  content: "\e9ee";
}
.icon-align-center:before {
  content: "\e9ef";
}
.icon-align-justified:before {
  content: "\e9f0";
}
.icon-align-left:before {
  content: "\e9f1";
}
.icon-align-right:before {
  content: "\e9f2";
}
.icon-arrow-down:before {
  content: "\e9f3";
}
.icon-arrow-left:before {
  content: "\e9f4";
}
.icon-arrow-outline-down:before {
  content: "\e9f5";
}
.icon-arrow-outline-left:before {
  content: "\e9f6";
}
.icon-arrow-outline-right:before {
  content: "\e9f7";
}
.icon-arrow-outline-up:before {
  content: "\e9f8";
}
.icon-arrow-right:before {
  content: "\e9f9";
}
.icon-arrow-thin-down:before {
  content: "\e9fa";
}
.icon-arrow-thin-left:before {
  content: "\e9fb";
}
.icon-arrow-thin-right:before {
  content: "\e9fc";
}
.icon-arrow-thin-up:before {
  content: "\e9fd";
}
.icon-arrow-up:before {
  content: "\e9fe";
}
.icon-attachment3:before {
  content: "\e9ff";
}
.icon-backspace:before {
  content: "\ea00";
}
.icon-backward:before {
  content: "\ea01";
}
.icon-backward-step:before {
  content: "\ea02";
}
.icon-block2:before {
  content: "\ea03";
}
.icon-bookmark1:before {
  content: "\ea04";
}
.icon-bookmarkcopy2:before {
  content: "\ea05";
}
.icon-bookmarkcopy3:before {
  content: "\ea06";
}
.icon-bookmark-outline:before {
  content: "\ea07";
}
.icon-bookmark-outline-add:before {
  content: "\ea08";
}
.icon-browser-window:before {
  content: "\ea09";
}
.icon-browser-window-new:before {
  content: "\ea0a";
}
.icon-chart-bar:before {
  content: "\ea0b";
}
.icon-chart-pie:before {
  content: "\ea0c";
}
.icon-checkmark:before {
  content: "\ea0d";
}
.icon-checkmark-outline:before {
  content: "\ea0e";
}
.icon-cheveron-down:before {
  content: "\ea0f";
}
.icon-cheveron-left:before {
  content: "\ea10";
}
.icon-cheveron-outline-down:before {
  content: "\ea11";
}
.icon-cheveron-outline-left:before {
  content: "\ea12";
}
.icon-cheveron-outline-right:before {
  content: "\ea13";
}
.icon-cheveron-outline-up:before {
  content: "\ea14";
}
.icon-cheveron-right:before {
  content: "\ea15";
}
.icon-cheveron-up:before {
  content: "\ea16";
}
.icon-clipboard:before {
  content: "\ea17";
}
.icon-close:before {
  content: "\ea18";
}
.icon-close-outline:before {
  content: "\ea19";
}
.icon-close-solid:before {
  content: "\ea1a";
}
.icon-code1:before {
  content: "\ea1b";
}
.icon-cog:before {
  content: "\ea1c";
}
.icon-compose:before {
  content: "\ea1d";
}
.icon-copy1:before {
  content: "\ea1e";
}
.icon-dashboard:before {
  content: "\ea1f";
}
.icon-directions:before {
  content: "\ea20";
}
.icon-document:before {
  content: "\ea21";
}
.icon-document-add:before {
  content: "\ea22";
}
.icon-dots-horizontal-double:before {
  content: "\ea23";
}
.icon-dots-horizontal-triple:before {
  content: "\ea24";
}
.icon-download:before {
  content: "\ea25";
}
.icon-duplicate:before {
  content: "\ea26";
}
.icon-edit-copy:before {
  content: "\ea27";
}
.icon-edit-crop:before {
  content: "\ea28";
}
.icon-edit-cut:before {
  content: "\ea29";
}
.icon-edit-pencil:before {
  content: "\ea2a";
}
.icon-exclamation-outline:before {
  content: "\ea2b";
}
.icon-folder-outline:before {
  content: "\ea2c";
}
.icon-folder-outline-add:before {
  content: "\ea2d";
}
.icon-format-bold:before {
  content: "\ea2e";
}
.icon-format-font-size:before {
  content: "\ea2f";
}
.icon-format-italic:before {
  content: "\ea30";
}
.icon-format-text-size:before {
  content: "\ea31";
}
.icon-format-underline:before {
  content: "\ea32";
}
.icon-globe1:before {
  content: "\ea33";
}
.icon-hour-glass:before {
  content: "\ea34";
}
.icon-indent-decrease:before {
  content: "\ea35";
}
.icon-indent-increase:before {
  content: "\ea36";
}
.icon-layers1:before {
  content: "\ea37";
}
.icon-light-bulb:before {
  content: "\ea38";
}
.icon-link1:before {
  content: "\ea39";
}
.icon-list3:before {
  content: "\ea3a";
}
.icon-list-add:before {
  content: "\ea3b";
}
.icon-list-bullet:before {
  content: "\ea3c";
}
.icon-load-balancer:before {
  content: "\ea3d";
}
.icon-menu3:before {
  content: "\ea3e";
}
.icon-minus-outline:before {
  content: "\ea3f";
}
.icon-navigation-more:before {
  content: "\ea40";
}
.icon-network:before {
  content: "\ea41";
}
.icon-notifications-outline:before {
  content: "\ea42";
}
.icon-pause-outline:before {
  content: "\ea43";
}
.icon-pen-tool1:before {
  content: "\ea44";
}
.icon-playlist:before {
  content: "\ea45";
}
.icon-refresh1:before {
  content: "\ea46";
}
.icon-reload:before {
  content: "\ea47";
}
.icon-reply:before {
  content: "\ea48";
}
.icon-reply-all:before {
  content: "\ea49";
}
.icon-screen-full:before {
  content: "\ea4a";
}
.icon-share-alt:before {
  content: "\ea4b";
}
.icon-show-sidebar:before {
  content: "\ea4c";
}
.icon-shuffle:before {
  content: "\ea4d";
}
.icon-stand-by:before {
  content: "\ea4e";
}
.icon-subdirectory-left:before {
  content: "\ea4f";
}
.icon-subdirectory-right:before {
  content: "\ea50";
}
.icon-tag:before {
  content: "\ea51";
}
.icon-text-box:before {
  content: "\ea52";
}
.icon-text-decoration:before {
  content: "\ea53";
}
.icon-time:before {
  content: "\ea54";
}
.icon-timer1:before {
  content: "\ea55";
}
.icon-tuning:before {
  content: "\ea56";
}
.icon-user-solid-circle:before {
  content: "\ea57";
}
.icon-view-tile:before {
  content: "\ea58";
}
.icon-window:before {
  content: "\ea59";
}
.icon-window-new:before {
  content: "\ea5a";
}
.icon-zoom-in:before {
  content: "\ea5b";
}
.icon-zoom-out:before {
  content: "\ea5c";
}
